import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export default function SEO({ children, location, description, title, image }) {
  return (
    <StaticQuery
      query={graphql`
        query SettingQuery {
          allSanitySiteSettings {
            nodes {
              keywords
              og_type
              og_url
              title
              description
              favicon {
                asset {
                  fluid {
                    src
                  }
                }
              }
              og_image {
                asset {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const resource = data.allSanitySiteSettings.nodes[0]
        console.log(resource)
        return (
          <Helmet titleTemplate={`%s - ${resource.title}`}>
            <html lang="en" />
            <title>{resource.title}</title>
            {/* Fav Icons */}

            <link
              rel="alternate icon"
              href={resource.favicon.asset.fluid.src}
            />
            {/* Meta Tags */}
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <meta charSet="utf-8" />
            <meta name="description" content={resource.description} />
            {/* Open Graph */}
            <meta property="og:url" content={resource.og_url} />
            <meta
              property="og:image"
              content={resource.og_image.asset.fluid.src}
            />
            <meta property="og:title" content={resource.title} key="ogtitle" />
            <meta
              propery="og:site_name"
              content={resource.title}
              key="ogsitename"
            />
            <meta
              property="og:description"
              content={resource.description}
              key="ogdesc"
            />
            {children}
          </Helmet>
        )
      }}
    />
  )
}
